@keyframes loading
{
	0% {opacity: 1;}
	50% {opacity: .3;}
	100% {opacity: 1;}
}
 
@-webkit-keyframes loading
{
	0% {opacity: 1;}
	50% {opacity: .3;}
	100% {opacity: 1;}
}



.Mobileroot{
	min-width: auto !important;
	min-height: auto !important;
}

.MobileMain_window{
	width: 100%;
	height: 100%;
	background-color: #101610;

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;

	overflow: hidden;
}

/*Main_loadingScreen*/
.MobileMain_loadingScreen{
	width: 100%;
	height: 100%;
	background-color: #000f06;
	opacity: 1;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 9999;
}

.MobileMain_loadingScreen .MobileMain_loadingScreen_pic{
	width: 180px;
	height: 180px;
	margin: 0 auto;
	padding-top: 180px;
}

.MobileMain_loadingScreen .loading_text{
	width: 100%;
	height: auto;
	font-size: 16px;
	color: #FFE18A;
	text-align: center;
	line-height: 1;
	font-weight: 400;
	text-shadow: 0px 0px 4px rgba(255, 255, 138, .6);
	margin-top: 12px;
	opacity: 1;
}

.MobileMain_loadingScreen .loading_text i{
	font-style: normal;
	animation: loading 2s linear 0s infinite normal;
	-webkit-animation: loading 2s linear 0s infinite normal;
}

.MobileMain_loadingScreen .loading_done{
	width: 186px;
	height: 20px;
	margin: 0 auto;
	background-image: url(../resource/images/logo_text@2x.png);
	background-size: 100% auto;
	background-position: bottom center;
	background-repeat: no-repeat;
	opacity: 0;
}

.MobileMain_loadingDone{
	opacity: 0;
}

.MobileMain_loadingDone .loading_text{
	height: 0px;
	opacity: 0;
}

.MobileMain_loadingDone .loading_done{
	opacity: 1;
}

.MobileMain_loadingNone{
	width: 0px;
	height: 0px;
	overflow: hidden;
	display: none !important;
	z-index: 1 !important;
}

/*Main_box*/
.MobileMain_box{
	width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: 1px 0px;

	position: relative;
}

.MobileMain_box_bg{
	width: 100%;
	height: 100%;
	background-image: url(../resource/images/BG_normal.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	-webkit-filter:blur(30px);/*Chrome,Opera*/
	-moz-filter:blur(30px);
	-ms-filter:blur(30px);
	filter:blur(30px);
	filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius=30,MakeShadow=false);/*IE6~IE9*/

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}

/*MobileMain_headNav*/
.MobileMain_headNav{
	width: 100%;
	height: auto;

	position: fixed;
	bottom: 0px;
	left: 0px;
	z-index: 3;
}

		/*DeviceAlert*/
.MobileMain_headNav .DeviceAlert{
	width: 5.2rem;
	height: auto;
	padding: .06rem 0px;
	margin: 0 auto;
	margin-bottom: .12rem;
	background-color: rgba(0, 0, 0, .8);
	border-radius: 100px;
	text-align: center;
}

.MobileMain_headNav .DeviceAlert .icon{
	width: .54rem;
	height: .54rem;
	background-image: url(../resource/images/icon_alert@2x.png);
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: middle;
	margin-right: .06rem;
}

.MobileMain_headNav .DeviceAlert i{
	width: auto;
	height: auto;
	font-size: .24rem;
	color: #FFE18A;
	text-align: left;
	line-height: 1;
	font-weight: 500;
	font-style: normal;
	display: inline-block;
	vertical-align: middle;
}

.MobileMain_headNav ul{
	width: 100%;
	height: auto;
	background-color: rgba(0, 0, 0, .7);

	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);

	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
}

.MobileMain_headNav ul li{
	width: 2.5rem;
	height: auto;
	padding: .3rem 0px .36rem 0px;
	position: relative;
}

.MobileMain_headNav ul li:after{
	content: '';
	width: 0px;
	height: .04rem;
	background-color: #FFE18A;
	border-radius: 100px;
	box-shadow: 0px -2px 6px rgba(255, 255, 138, .5);

	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 2;

	-webkit-transition: all 360ms ease;
	transition: all 360ms ease;

	-webkit-transform: translate3d(0, 6px, 0);
	transform: translate3d(0, 6px, 0);

	opacity: 0;
}

.MobileMain_headNav ul li p{
	width: auto;
	height: auto;
	padding-left: .54rem;
	font-size: .36rem;
	text-align: left;
	line-height: 1;
	color: #FFE18A;
	font-weight: 500;
	text-shadow: 0px 2px 4px rgba(255, 255, 138, 0);

	position: relative;
	z-index: 3;

	opacity: .6;
}

.MobileMain_headNav ul li em{
	width: 100%;
	height: auto;
	font-size: .3rem;
	text-align: left;
	line-height: 1;
	color: #ffffff;
	opacity: .1;
	font-weight: 400;

	position: absolute;
	bottom: .24rem;
	left: .78rem;
	z-index: 1;
}

.MobileMain_headNav ul li:hover p{
	text-shadow: 0px 2px 4px rgba(255, 255, 138, 1);
	opacity: 1;
}

.MobileMain_headNav ul li:hover em{
	-webkit-transform: translate3d(.16rem, 0, 0);
	transform: translate3d(.16rem, 0, 0);
	opacity: .3;
}

.MobileMain_headNav ul li.nav_cur:after{
	width: 100%;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	opacity: 1;
}

.MobileMain_headNav ul li.nav_cur p{
	opacity: 1;
}

.MobileMain_headNav ul li.nav_cur em{
	opacity: .3;
}

.MobileMain_headNav_fixed{
	z-index: 99;
	background-color: rgba(0, 0, 0, .8);
}

/*MobileMain_logo*/
.MobileMain_logo{
	width: 100%;
	height: auto;
	padding: .3rem 0px .6rem 0px;
	background-image: linear-gradient(to bottom, rgba(0,0,0,.6), rgba(0,0,0,0));

	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 3;
}

.MobileMain_logo .logo_pic{
	width: .8rem;
	height: .8rem;
	margin: 0 auto;
	background-image: url(../resource/images/logo_main@2x.png);
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
	background-color: #fff;
	border-radius: .12rem;
	box-shadow: 0px .04rem .24rem rgba(255, 236, 163, 1);
}

.MobileMain_logo .logo_text{
	width: 3.72rem;
	height: .37rem;
	margin: 0 auto;
	background-image: url(../resource/images/logo_text@2x.png);
	background-size: 100% auto;
	background-position: bottom center;
	background-repeat: no-repeat;
	margin-top: .3rem;
}



.MobileMain_window .MobileMain_Window_PageList{
	width: 100%;
	height: 100%;

	position: relative;
	z-index: 2;	
}



/*MobileMain_item*/
.MobileMain_item{

}

.MobileMain_item ul{
	width: 100%;
	height: 100%;
}

.MobileMain_item li{
	width: 3rem;
	height: 4rem;
	border-radius: .08rem;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	opacity: .8;

	pointer-events: auto; /*解决pointer-events:none,阻止anchor元素的默认行为 （涉及模块Parallax.js）*/

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;

	background-color: #eee;
}

.MobileMain_item li p{
	width: 100%;
	height: auto;
	font-size: .3rem;
	text-align: left;
	color: #FFE18A;
	line-height: 1;
	font-weight: 500;

	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	position: absolute;
	bottom: .3rem;
	left: .45rem;
	z-index: 3;
}

.MobileMain_item li .icon_enter{
	width: .2rem;
	height: .16rem;
	background-image: url(../resource/images/icon_enter@2x.png);
	background-size: 70px 8px;
	background-position: top right;
	background-repeat: no-repeat;

	opacity: 0;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	position: absolute;
	bottom: 1.28rem;
	left: .64rem;
	z-index: 3;
}

.MobileMain_item li em{
	width: 100%;
	height: auto;
	font-size: .75rem;
	text-align: right;
	color: #ffffff;
	line-height: 1;
	font-weight: 500;
	opacity: .3;

	position: absolute;
	bottom: .3rem;
	left: .18rem;
	z-index: 1;	
}

.MobileMain_item li .mask{
	width: 100%;
	height: 2rem;
	background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.8));

	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 2;
}

.MobileMain_item .animate_hover:hover p{
	-webkit-transform: translate3d(0, -1.12rem, 0);
	transform: translate3d(0, -1.12rem, 0);
}

.MobileMain_item .animate_hover:hover .icon_enter{
	width: 1.4rem;
	opacity: 1;
	-webkit-transform: translate3d(.12rem, 0, 0);
	transform: translate3d(.12rem, 0, 0);
}

.MobileMain_item .animate_hover:hover em{
	opacity: .6;
}

.MobileMain_item .animate_hover:hover .mask{
	height: 6.4rem;
}

/*page_Portfolio*/
.MobileMain_item .page_Portfolio{
	opacity: 1;
	box-shadow: 2px 4px 12px rgba(58, 68, 28, 1);
	border: 1px solid #46541E;
}

.MobileMain_item .page_Portfolio:hover{
	box-shadow: 2px 4px 12px rgba(255, 255, 138, .5);
	border: 1px solid #FFE18A;
}

/*page_Laboratory*/
.MobileMain_item .page_Laboratory{
	opacity: 1;
	box-shadow: 2px 4px 12px rgba(26, 88, 26, .5);
	border: 1px solid #1D3616;
}

.MobileMain_item .page_Laboratory:hover{
	box-shadow: 2px 4px 12px rgba(167, 236, 102, .5);
	border: 1px solid #2A5C1C;
}

/*switch pages*/
.MobileMain_window .Window_PageItem{
	width: 100%;
	height: 100%;

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}

.MobileMain_window .Switch_page_fadeIn{
	opacity: 1;
	z-index: 99 !important;
}

.MobileMain_window .Switch_page_fadeOut{
	opacity: 0;
	transform: scale3d(2, 2, 2);
	-webkit-transform: scale3d(2, 2, 2); /* Safari 与 Chrome */

	z-index: 1 !important;
}

.MobileMain_window .Switch_page_fadeOut li{
	pointer-events: none;
}





/*Portfolio_item*/
.MobileMain_window .Portfolio_item{

}






/*Laboratory_item*/
.MobileMain_window .Laboratory_item{

}





