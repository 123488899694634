.Articles_window{
	width: 100%;
	height: auto;
	background-color: #EFF5F3;

	position: relative;
	z-index: 10;
}

.Articles_banner{
	width: 100%;
	height: auto;
	background-color: #EFF5F3;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, .1);
	margin-bottom: 12px;
	position: relative;
	z-index: 2;
}

.Articles_content{
	width: 100%;
	height: auto;
}

.Articles_content .pic{
	width: 100%;
	height: auto;
	margin: 0px;
	padding: 0px;
	font-size: 0px;
	display: block;
	background-color: #EFF5F3;
}

.Articles_content .pic:last-of-type{
	margin-bottom: 0px;
}





