body{
	padding: 0px;
	margin: 0px;
}

.ThreeDmodel_box{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}

.loading_line{
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .8);
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1000;

	display: none !important;
}

.loading_line .loading_text{
	width: 100%;
	height: auto;
	font-size: 16px;
	color: #FFE18A;
	text-align: center;
	line-height: 1;
	font-family: serif;
	font-weight: 700;
}

.loading_line .loading_text:before{
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 60px;
	height: 2px;
	background-color: #FFE18A;
	opacity: .3;
	margin-right: 12px;
}

.loading_line .loading_text:after{
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 60px;
	height: 2px;
	background-color: #FFE18A;
	opacity: .3;
	margin-left: 12px;
}

.loading_line .icon_point{
	width: 12px;
	height: 12px;
	margin: 12px auto;
	background-image: url(../resource/images/icon_point@2x.png);
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
}

.loading_line .loading_progress{
	width: 200px;
	height: auto;
	padding: 6px 0px;
	border-radius: 100px;
	background-color: #BCB08E;
	position: relative;
	overflow: hidden;
}

.loading_line .loading_progress .progress_now{
	width: 50%;
	height: 100%;
	background-color: #FFE18A;
	border-radius: 100px;
	box-shadow: 2px 0px 6px rgba(255, 225, 138, .5);

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}

.loading_line .loading_progress .progress_num{
	width: 100%;
	height: auto;
	font-size: 12px;
	color: #745E23;
	text-align: center;
	line-height: 1;
	font-weight: 700;

	position: relative;
	z-index: 2;
}

.promptPage{
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .8);
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1001;
}

.promptPage .prompt_pic{
	width: 420px;
	height: 240px;
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
}

.promptPage .prompt_text{
	width: 100%;
	height: auto;
	text-align: center;
	margin-top: 12px;
	margin-bottom: 36px;
}

.promptPage .prompt_text:before{
	content: '';
	width: 60px;
	height: 2px;
	display: inline-block;
	vertical-align: middle;
	background-color: #FFE18A;
	opacity: .3;
	margin-right: 12px;
}

.promptPage .prompt_text:after{
	content: '';
	width: 60px;
	height: 2px;
	display: inline-block;
	vertical-align: middle;
	background-color: #FFE18A;
	opacity: .3;
	margin-left: 12px;
}

.promptPage .prompt_text p{
	width: 136px;
	height: auto;
	display: inline-block;
	vertical-align: middle;
	font-size: 16px;
	color: #FFE18A;
	text-align: center;
	line-height: 1.625;
	font-family: serif;
}

.promptPage .btn_prompt_done{
	width: 180px;
	height: auto;
	padding: 12px 0px;
	font-size: 18px;
	color: #745E23;
	text-align: center;
	line-height: 1;
	font-weight: 700;
	background-color: #FFE18A;
	border-radius: 100px;
}

.promptPage .btn_prompt_done:hover{
	background-color: #DEB84A;
}

.promptPage_hide{
	display: none;
}

.loading_line_hide{
	display: none;
}





