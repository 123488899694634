body{
	padding: 0px;
	margin: 0px;
}

.MobileThreeDmodel_box{
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}

.MobileThreeDmodel_box .loading_line{
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .8);
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1000;
}

.MobileThreeDmodel_box .loading_line .loading_text{
	width: 100%;
	height: auto;
	font-size: .32rem;
	color: #FFE18A;
	text-align: center;
	line-height: 1;
	font-family: serif;
	font-weight: 700;
}

.MobileThreeDmodel_box .loading_line .loading_text:before{
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 1.2rem;
	height: .04rem;
	background-color: #FFE18A;
	opacity: .3;
	margin-right: .24rem;
}

.MobileThreeDmodel_box .loading_line .loading_text:after{
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 1.2rem;
	height: .04rem;
	background-color: #FFE18A;
	opacity: .3;
	margin-left: .24rem;
}

.MobileThreeDmodel_box .loading_line .icon_point{
	width: .24rem;
	height: .24rem;
	margin: .24rem auto;
	background-image: url(../resource/images/icon_point@2x.png);
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
}

.MobileThreeDmodel_box .loading_line .loading_progress{
	width: 4rem;
	height: auto;
	padding: .12rem 0px;
	border-radius: 100px;
	background-color: #BCB08E;
	position: relative;
	overflow: hidden;
}

.MobileThreeDmodel_box .loading_line .loading_progress .progress_now{
	width: 50%;
	height: 100%;
	background-color: #FFE18A;
	border-radius: 100px;
	box-shadow: .04rem 0px .12rem rgba(255, 225, 138, .5);

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}

.MobileThreeDmodel_box .loading_line .loading_progress .progress_num{
	width: 100%;
	height: auto;
	font-size: .24rem;
	color: #745E23;
	text-align: center;
	line-height: 1;
	font-weight: 400;

	position: relative;
	z-index: 2;
}

.MobileThreeDmodel_box .promptPage{
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .8);
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1001;
}

.MobileThreeDmodel_box .promptPage .prompt_pic{
	width: 4.2rem;
	height: 2.4rem;
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
}

.MobileThreeDmodel_box .promptPage .prompt_text{
	width: 100%;
	height: auto;
	text-align: center;
	margin-top: .24rem;
	margin-bottom: .72rem;
}

.MobileThreeDmodel_box .promptPage .prompt_text:before{
	content: '';
	width: 1.2rem;
	height: .04rem;
	display: inline-block;
	vertical-align: middle;
	background-color: #FFE18A;
	opacity: .3;
	margin-right: .24rem;
}

.MobileThreeDmodel_box .promptPage .prompt_text:after{
	content: '';
	width: 1.2rem;
	height: .04rem;
	display: inline-block;
	vertical-align: middle;
	background-color: #FFE18A;
	opacity: .3;
	margin-left: .24rem;
}

.MobileThreeDmodel_box .promptPage .prompt_text p{
	width: 2.56rem;
	height: auto;
	display: inline-block;
	vertical-align: middle;
	font-size: .32rem;
	color: #FFE18A;
	text-align: center;
	line-height: 1.625;
	font-family: serif;
}

.MobileThreeDmodel_box .promptPage .btn_prompt_done{
	width: 3.6rem;
	height: auto;
	padding: .24rem 0px;
	font-size: .36rem;
	color: #745E23;
	text-align: center;
	line-height: 1;
	font-weight: 400;
	background-color: #FFE18A;
	border-radius: 100px;
}

.MobileThreeDmodel_box .promptPage .btn_prompt_done:hover{
	background-color: #DEB84A;
}

.MobileThreeDmodel_box .promptPage_hide{
	display: none;
}

.MobileThreeDmodel_box .loading_line_hide{
	display: none;
}





