/* CSS Document */
/*======== 全局 ========*/
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, p, blockquote, th, td { margin:0; padding:0; }
body { font-size:12px; color:#666; background:#fff; line-height:24px; }
/*body{
	font-family: "PingFang SC", "Lantinghei SC", "Helvetica Neue", "Helvetica", "Arial", "Microsoft YaHei", "\\5FAE\8F6F\96C5\9ED1", "STHeitiSC-Light", "simsun", "\\5B8B\4F53", "WenQuanYi Zen Hei", "WenQuanYi Micro Hei", "sans-serif", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-feature-settings: "kern", "liga";
	font-feature-settings: "kern", "liga";
}*/

/*非衬线字体族*/
*{
	-webkit-locale: auto;
}
@font-face {
	font-family: Emoji;
	src: local("Apple Color Emojiji"), local("Segoe UI Emoji"), local("Segoe UI Symbol"), local("Noto Color Emoji");
	unicode-range: U+1F000-1F644, U+203C-3299;
}
body {
	font-family: system-ui, —apple-system, Segoe UI, Rototo, Emoji, Helvetica, Arial, sans-serif;

	background-color: #101610;
}

/*衬线字体族*/
.font-serif {
	font-family: Georgia, Cambria, "Times New Roman", Times, "simsun", serif;
}

/*等宽字体族*/
.font-mono {
	font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

fieldset, img { border:0;}
ol, ul { list-style:none; }
h1, h2, h3, h4, h5, h6{ font-size:100%; }
em { font-style:normal; }
input, button, select, textarea { outline:none; } 
textarea { resize:none; } /*禁用了文本的拖拉，尤其在谷歌下
*/p{ text-align:justify; text-justify:distribute;} /*为了使文本段落左右两边对齐*/
/*======== Link ========*/
a { color: #7BBEF7; text-decoration:none; }

div{
        padding: 0;
        margin: 0;
        border: 0;
        line-height: 1;
		background-clip: padding-box;	/*某些Android手机圆角失效*/
		border-width: thin;	/*Retina屏的1px边框*/
}

div,input{	/*ios和android下触摸元素时出现半透明灰色遮罩*/
 	-webkit-tap-highlight-color:rgba(255,255,255,0);
}

/* 滚动条凹槽的颜色，还可以设置边框属性 */
*::-webkit-scrollbar-track-piece {
	background-color: #ffffff;
	-webkit-border-radius: 0 12px 12px 0;
	-moz-border-radius: 0 12px 12px 0;
	border-radius: 0 12px 12px 0;

	float: right;
}
 
/* 滚动条的宽度 */
*::-webkit-scrollbar {
	width: 6px;
	height: 6px;

	display: none;
}
 
/* 滚动条的设置 */
*::-webkit-scrollbar-thumb {
	background-color: #ddd;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
	background-clip: padding-box;
}
 
/* 滚动条鼠标移上去 */
*::-webkit-scrollbar-thumb:hover {
	background-color: #bbb;
}

#root{
	min-width: 1200px;
	min-height: 900px;
	position: relative;
	overflow: hidden;
}

#root .App{
	width: 100%;
}

/*widowSize_start*/
.set_widowSize{
	min-width: 1200px;
	overflow: hidden;
}
/*widowSize_end*/


/*animate_start*/
.normal_animate{
	-webkit-transition: all 360ms ease;
	transition: all 360ms ease;
}

.fast_animate{
	-webkit-transition: all 240ms ease;
	transition: all 240ms ease;	
}

.delay_animate_180{
	-webkit-transition: all 360ms ease 180ms;
	transition: all 360ms ease 180ms;
}

.delay_animate_360{
	-webkit-transition: all 360ms ease 360ms;
	transition: all 360ms ease 360ms;
}

.delay_animate_460{
	-webkit-transition: all 360ms ease 460ms;
	transition: all 360ms ease 460ms;
}

.delay_animate_600{
	-webkit-transition: all 360ms ease 600ms;
	transition: all 360ms ease 600ms;
}

.delay_animate_900{
	-webkit-transition: all 360ms ease 900ms;
	transition: all 360ms ease 900ms;
}

.delay_animate_1200{
	-webkit-transition: all 1200ms cubic-bezier(.34,.35,0,.97) 0ms;
	transition: all 1200ms cubic-bezier(.34,.35,0,.97) 0ms;
}

.animate_hover{
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	cursor: pointer;
}
/*animate_end*/

.display_Flex{
	display: flex;
	flex-wrap: wrap;
}

.display_inlineFlex{
	display: inline-flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.display_inlineBlock{
	display: inline-block;
	vertical-align: top;
}

.display_inlineFlex_center{
	justify-content: center;
}

.display_inlineFlex_alignCenter{
	align-items: center;
}

/*icon_tool_start*/
.icon_tool{
	background-image: url(../resource/images/tool/icon_tool@2x.png);
	background-size: 462px 42px;
	background-position: 0px 0px;
	background-repeat: no-repeat;
}

.icon_tool_Sketch{
	background-position: 0px 0px;
}

.icon_tool_Ae{
	background-position: -42px 0px;
}

.icon_tool_H5{
	background-position: -84px 0px;
}

.icon_tool_WeChat{
	background-position: -126px 0px;
}

.icon_tool_Ps{
	background-position: -168px 0px;
}

.icon_tool_Ai{
	background-position: -210px 0px;
}

.icon_tool_C4D{
	background-position: -252px 0px;
}

.icon_tool_ThreeJS{
	background-position: -294px 0px;
}

.icon_tool_ArtStudio{
	background-position: -336px 0px;
}

.icon_tool_Figma{
	background-position: -378px 0px;
}

.icon_tool_Blender{
	background-position: -420px 0px;
}
/*icon_tool_end*/

/*img_set_start*/
.img_unselecTable{
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
}



