.Portfolioinfo_window{
	width: 100%;
	height: auto;
}

.Portfolioinfo_window h3{
	width: 100%;
	height: auto;
	font-size: 20px;
	color: #2A3033;
	text-align: left;
	line-height: 1.2;
}

.Portfolioinfo_window h4{
	width: 100%;
	height: auto;
	font-size: 16px;
	color: #2A3033;
	text-align: left;
	line-height: 1;	
	opacity: .8;
}

.Portfolioinfo_window .date{
	width: 100%;
	height: auto;
	display: block;
	margin-top: 12px;
	font-size: 14px;
	text-align: left;
	line-height: 1;
	color: #2A3033;
	opacity: .4;
}

.Portfolioinfo_window .content{
	width: 100%;
	height: auto;
	display: block;
	margin-top: 24px;
}

.Portfolioinfo_window .content p{
	width: 100%;
	height: auto;
	display: block;
	margin-bottom: 16px;
	font-size: 14px;
	font-weight: 320;
	text-align: left;
	line-height: 1.625;
	color: #878b8d; /*opacity 0.6*/
}

.Portfolioinfo_window .content p img{
	width: 100%;
	height: auto;
	margin: 0;
	padding: 0;
}

.Portfolioinfo_window .content p:last-of-type{
	margin-bottom: 0px;
}

.Portfolioinfo_window .content em{
	color: #666;
	font-weight: 460;
	margin-top: 12px;
	line-height: 2;
}

.Portfolioinfo_window .content a{
	width: auto;
	height: auto;
	display: inline-block;
 	padding: 9px;
	vertical-align: middle;
	font-size: 16px;
	color: #fff;
	text-align: center;
	line-height: 1;
	border-radius: 6px;
	background-color: #90C966;
	box-shadow: 0px 2px 6px rgba(144, 201, 102, .5);
	opacity: 1;
	margin-top: 12px;
}

.Portfolioinfo_window .content a:hover{
	background-color: #60933a;
}

.Portfolioinfo_window .label{
	width: 100%;
	height: auto;
	margin-top: 24px;
}

.Portfolioinfo_window .label li{
	width: auto;
	height: auto;
	padding: 6px 12px;
	margin-top: 12px;
	margin-right: 12px;
	font-size: 14px;
	text-align: center;
	line-height: 1;
	color: #90C966;
	font-weight: 430;
	border-radius: 6px;
	background-color: #ECF7EB;
}

.Portfolioinfo_window .tool{
	width: 100%;
	height: auto;
	margin-top: 60px;
}

.Portfolioinfo_window .tool li{
	width: 42px;
	height: 42px;
	margin-top: 12px;
	margin-right: 10px;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0px 2px 6px rgba(194, 206, 186, .5);
}

.Portfolioinfo_window .tool li:last-of-type{
	margin-right: 0px;
}

.Portfolioinfo_window .color{
	width: 100%;
	height: auto;
	margin-top: 24px;
}

.Portfolioinfo_window .color ul{
	width: auto;
	height: 30px;
	margin-top: 12px;
	position: relative;
	z-index: 2;
}

.Portfolioinfo_window .color ul li{
	width: 30px;
	height: 30px;
	cursor: pointer;

	position: relative;
}

.Portfolioinfo_window .color ul li span{
	width: 60px;
	height: auto;
	padding: 6px 12px;
	font-size: 14px;
	text-align: left;
	line-height: 1;
	color: #7F8385; /*2A3033 opacity0.6*/
	font-weight: 400;
	background-color: #ffffff;
	box-shadow: 0px 2px 6px rgba(194, 206, 186, .5);
	border-radius: 6px;
	opacity: 0;

	position: absolute;
	bottom: -24px;
	left: 12%;
	z-index: 1;
}

.Portfolioinfo_window .color ul li span:after{
	content: '';
	width: 8px;
	height: 8px;
	background-color: #ffffff;
	border-radius: 2px 0px 0px 0px;
	transform: rotate(45deg);

	position: absolute;
	bottom: 20px;
	left: 8px;
	z-index: 1;
}

.Portfolioinfo_window .color ul li:first-of-type{
	width: 70px;
	border-radius: 6px 0px 0px 6px;
}

.Portfolioinfo_window .color ul li:last-of-type{
	width: 20px;
	border-radius: 0px 6px 6px 0px;
}

.Portfolioinfo_window .color ul li:hover span{
	opacity: 1;
}

.Portfolioinfo_window .color .colorShadow{
	margin-top: -30px;
	z-index: 1;
}

.Portfolioinfo_window .reference{
	width: 100%;
	height: auto;
	margin-top: 36px;
}

.Portfolioinfo_window .reference li{
	width: 100%;
	height: auto;
	margin-top: 12px;
}

.Portfolioinfo_window .reference li .pic{
	width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0px 2px 6px rgba(194, 206, 186, .5);
}





