.MobileArticles_window{
	width: 100%;
	height: auto;
	background-color: #EFF5F3;

	position: relative;
	z-index: 10;
}

.MobileArticles_banner{
	width: 100%;
	height: auto;
	background-color: #EFF5F3;
	box-shadow: 0px .06rem .12rem rgba(173, 207, 194, .2);
	position: relative;
	z-index: 2;
}

.MobileArticles_content{
	width: 100%;
	height: auto;
}

.MobileArticles_content .pic{
	width: 100%;
	height: auto;
	background-color: #EFF5F3;
	margin-bottom: .12rem;
}

.MobileArticles_content .pic:last-of-type{
	margin-bottom: 0px;
}





