/*normal*/
.MobilePortfolioinfo_window{
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}

.MobilePortfolioinfo_window h3{
	width: 100%;
	height: auto;
	font-size: .36rem;
	color: #2A3033;
	text-align: left;
	line-height: 1.2;
}

.MobilePortfolioinfo_window h4{
	width: 100%;
	height: auto;
	font-size: .3rem;
	color: #2A3033;
	text-align: left;
	line-height: 1;	
	opacity: .8;
}

.MobilePortfolioinfo_window .date{
	width: 100%;
	height: auto;
	display: block;
	margin-top: .24rem;
	font-size: .24rem;
	text-align: left;
	line-height: 1;
	color: #2A3033;
	opacity: .4;
}

.MobilePortfolioinfo_window .content{
	width: 100%;
	height: auto;
	display: block;
	margin-top: .36rem;
}

.MobilePortfolioinfo_window .content p{
	width: 100%;
	height: auto;
	display: block;
	margin-bottom: .24rem;
	font-size: .28rem;
	text-align: left;
	line-height: 1.625;
	color: #878b8d; /*opacity 0.6*/
}

.MobilePortfolioinfo_window .content p img{
	width: 50%;
	height: auto;
	display: block;
	margin: 0 auto;
	padding: 0;
}

.MobilePortfolioinfo_window .content em{
	font-weight: 460;
	margin-top: .12rem;
}

.MobilePortfolioinfo_window .content a{
	width: auto;
	height: auto;
	display: inline-block;
 	padding: .18rem;
	vertical-align: middle;
	font-size: .28rem;
	color: #fff;
	text-align: center;
	line-height: 1;
	border-radius: .12rem;
	background-color: #90C966;
	box-shadow: 0px .04rem .12rem rgba(144, 201, 102, .5);
	opacity: 1;
	margin-top: .18rem;
}

.MobilePortfolioinfo_window .content a:hover{
	background-color: #60933a;
}

.MobilePortfolioinfo_window .label{
	width: 100%;
	height: auto;
	margin-top: .36rem;
}

.MobilePortfolioinfo_window .label li{
	width: auto;
	height: auto;
	padding: .12rem .24rem;
	margin-top: .24rem;
	margin-right: .24rem;
	font-size: .28rem;
	text-align: center;
	line-height: 1;
	color: #90C966;
	font-weight: 400;
	border-radius: .06rem;
	background-color: #ECF7EB;
}

.MobilePortfolioinfo_window .tool{
	width: 100%;
	height: auto;
	margin-top: .42rem;
}

.MobilePortfolioinfo_window .tool li{
	width: .8rem;
	height: .8rem;
	margin-top: .24rem;
	margin-right: .24rem;
	background-color: #fff;
	border-radius: .12rem;
	box-shadow: 0px .04rem .12rem rgba(194, 206, 186, .5);
}

.MobilePortfolioinfo_window .color{
	width: 100%;
	height: auto;
	margin-top: .42rem;
}

.MobilePortfolioinfo_window .color ul{
	width: auto;
	height: .6rem;
	margin-top: .24rem;
	position: relative;
	z-index: 2;
}

.MobilePortfolioinfo_window .color ul li{
	width: .6rem;
	height: .6rem;
	cursor: pointer;

	position: relative;
}

.MobilePortfolioinfo_window .color ul li span{
	width: auto;
	height: auto;
	padding: .12rem .24rem;
	font-size: .28rem;
	text-align: left;
	line-height: 1;
	color: #7F8385; /*2A3033 opacity0.6*/
	font-weight: 400;
	background-color: #ffffff;
	box-shadow: 0px .04rem .12rem rgba(194, 206, 186, .5);
	border-radius: .12rem;
	opacity: 0;

	position: absolute;
	bottom: -.48rem;
	left: 12%;
	z-index: 1;
}

.MobilePortfolioinfo_window .color ul li span:after{
	content: '';
	width: .16rem;
	height: .16rem;
	background-color: #ffffff;
	border-radius: .04rem 0px 0px 0px;
	transform: rotate(45deg);

	position: absolute;
	bottom: .4rem;
	left: .16rem;
	z-index: 1;
}

.MobilePortfolioinfo_window .color ul li:first-of-type{
	width: 1.4rem;
	border-radius: 6px 0px 0px 6px;
}

.MobilePortfolioinfo_window .color ul li:last-of-type{
	width: .4rem;
	border-radius: 0px 6px 6px 0px;
}

.MobilePortfolioinfo_window .color ul li:hover span{
	opacity: 1;
}

.MobilePortfolioinfo_window .color .colorShadow{
	margin-top: -.6rem;
	z-index: 1;
}

/*morinfo*/
.MobileThreeDmodel_box .MobileModelinfo .MobileModelinfo_more{
	width: 100%;
	height: auto;
	border-radius: .12rem;
	background-color: rgba(255, 255, 255, .2);

	justify-content: center;
	align-items: center;
	align-content: center;
	flex-wrap: nowrap;
	flex-direction: row;

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 2;
}

.MobileThreeDmodel_box .MobileModelinfo .MobileModelinfo_more .icon{
	width: .48rem;
	height: .48rem;
	background-image: url(../resource/images/icon_more@2x.png);
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
}

.MobileThreeDmodel_box .MobileModelinfo .MobileModelinfo_more i{
	font-size: .24rem;
	color: #fff;
	font-style: normal;
}

/*model*/
.MobileThreeDmodel_box .MobileModelinfo{
	width: 100%;
	height: 2.04rem !important;
	background-color: rgba(0,0,0,.8) !important;
	overflow: hidden;
	border-radius: .12rem .12rem 0px 0px;

	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 2;
}

.MobileThreeDmodel_box .MobilePortfolioinfo_window h3{
	color: #fff;
}

.MobileThreeDmodel_box .MobilePortfolioinfo_window .date{
	color: #fff;
}

.MobileThreeDmodel_box .MobilePortfolioinfo_window .content p{
	color: #fff;
}

.MobileThreeDmodel_box .MobilePortfolioinfo_window .label{
	margin-top: .24rem;
}

.MobileThreeDmodel_box .MobilePortfolioinfo_window .label li{
	background-color: #4e674c;
}

.MobileThreeDmodel_box .MobilePortfolioinfo_window h4{
	color: #fff;
	opacity: .6;
}

/*open*/
.MobileThreeDmodel_box .MobileModelinfo_open{
	height: 8.2rem !important;
}

.MobileThreeDmodel_box .MobileModelinfo_open .MobileModelinfo_more .icon{
	transform: rotate(180deg);
}

.MobileThreeDmodel_box .MobileModelinfo_open .MobilePortfolioinfo_window{
	overflow-y: scroll;
}

.MobileThreeDmodel_box .MobileModelinfo_open .MobilePortfolioinfo_window > div{
	padding-bottom: 1.48rem;
}





