.MobilePortfolio_window{
	width: 100%;
	height: auto;
}

/*MobilePortfolio_headNav*/
.MobilePortfolio_window .MobilePortfolio_headNav{
	width: 100%;
	height: auto;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	margin-top: 2.24rem;

	position: relative;
	z-index: 2;
}

.MobilePortfolio_headNav li{
	width: 1.2rem;
	height: auto;
	margin: 0px .12rem;
}

.MobilePortfolio_headNav li:after{
	content: '';
	width: 0px;
	height: .02rem;
	background-color: #FFE18A;
	border-radius: 100px;
	box-shadow: 0px -2px 6px rgba(255, 255, 138, .5);

	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 2;

	-webkit-transition: all 360ms ease;
	transition: all 360ms ease;

	-webkit-transform: translate3d(0, 6px, 0);
	transform: translate3d(0, 6px, 0);

	opacity: 0;
}

.MobilePortfolio_headNav li p{
	width: auto;
	height: auto;
	padding: .12rem 0px;
	font-size: .24rem;
	text-align: center;
	line-height: 1;
	color: #FFE18A;
	text-shadow: 0px 2px 4px rgba(255, 255, 138, 0);

	position: relative;
	z-index: 3;

	opacity: .6;
}

.MobilePortfolio_headNav li:hover p{
	text-shadow: 0px 2px 4px rgba(255, 255, 138, 1);
	opacity: 1;
}

.MobilePortfolio_headNav li.nav_cur:after{
	width: 100%;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	opacity: 1;
}

.MobilePortfolio_headNav li.nav_cur p{
	opacity: 1;
}

.MobilePortfolio_headNav li.nav_cur em{
	opacity: .3;
}

/*MobilePortfolio_list*/
.MobilePortfolio_list{
	width: 100%;
	height: 10.14rem;
	padding: .3rem 0px;
	margin: 0px;
	flex-wrap: wrap;
	align-content: flex-start;
	overflow-x: hidden;
	overflow-y: scroll;
	position: relative;
	z-index: 2;
}

.MobilePortfolio_list li{
	width: 3.36rem;
	height: 2.1rem;
	padding: 0px;
	margin: 0px .09rem .18rem .09rem;
	list-style: none;
	background-color: #40574C;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: .08rem;
	border: 1px solid #1D3616;
	box-shadow: 2px 4px 12px rgba(26, 88, 26, .5);
	overflow: hidden;

	position: relative;
	z-index: 2;
}

.MobilePortfolio_list li:last-of-type{
	margin-bottom: 3.6rem;
}

.MobilePortfolio_list li p{
	width: calc(100% - .36rem);
	height: auto;
	padding: .24rem .24rem;
	margin: 0px;
	font-size: .28rem;
	font-weight: bold;
	color: #ffffff;
	line-height: 1.2;

	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 3;

	opacity: 0;
}

.MobilePortfolio_list li:after{
	content: '';
	width: 100%;
	height: 100%;
	background-image: linear-gradient(180deg, transparent 62%, rgba(0,0,0,0.00345888) 63.94%, rgba(0,0,0,0.014204) 65.89%, rgba(0,0,0,0.0326639) 67.83%, rgba(0,0,0,0.0589645) 69.78%, rgba(0,0,0,0.0927099) 71.72%, rgba(0,0,0,0.132754) 73.67%, rgba(0,0,0,0.177076) 75.61%, rgba(0,0,0,0.222924) 77.56%, rgba(0,0,0,0.267246) 79.5%, rgba(0,0,0,0.30729) 81.44%, rgba(0,0,0,0.341035) 83.39%, rgba(0,0,0,0.367336) 85.33%, rgba(0,0,0,0.385796) 87.28%, rgba(0,0,0,0.396541) 89.22%, rgba(0,0,0,0.4) 91.17%);

	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 2;

	-webkit-transition: all 360ms ease;
	transition: all 360ms ease;
	opacity: 0;
}

.MobilePortfolio_list li:hover{
	-webkit-transform: translate3d(0, -6px, 0);
	transform: translate3d(0, -6px, 0);
	border: 1px solid #FFE18A;
	box-shadow: 0px 12px 36px rgba(255, 255, 138, .5);
}

.MobilePortfolio_list li:hover p{
	opacity: 1;
}

.MobilePortfolio_list li:hover:after{
	opacity: 1;
}

/*loading*/
.MobilePortfolio_loadingScreen{
	width: 100%;
	height: 100%;
	margin-top: 1.8rem;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;

	opacity: 0;
}

.MobilePortfolio_loadingScreen_inner{
	width: 3.4rem;
	height: auto;
	margin: 0 auto;
	padding: .3rem;
	background-color: rgba(0,0,0,.7);
	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);
	border-radius: .12rem;
}

.MobilePortfolio_loadingScreen .MobilePortfolio_loadingScreen_pic{
	width: 2.8rem;
	height: auto;
	margin: 0 auto;
}

.MobilePortfolio_loadingScreen .loading_text{
	width: 100%;
	height: auto;
	font-size: .3rem;
	color: #FFE18A;
	text-align: center;
	line-height: 1;
	font-weight: 400;
	text-shadow: 0px 0px 4px rgba(255, 255, 138, .6);
	margin-top: .26rem;
}

.MobilePortfolio_loadingShow{
	opacity: 1;
	z-index: 3;
}

.MobilePortfolio_loadingClose{
	opacity: 0;
	z-index: 1;
}

/*MobilePortfolio_viewBox_start*/
.MobilePortfolio_viewBox{
	width: 100%;
	height: 100%;
	overflow: hidden;

	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 1;
}

.MobilePortfolio_viewBox .MobilePortfolio_viewBox_Mask{
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, .6);

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;	
}

.MobilePortfolio_viewBox .MobilePortfolio_viewBox_inner{
	width: 100%;
	height: calc(100% - 2.24rem);
	margin: 0 auto;
	margin-top: 2.24rem;

	-webkit-transform: translate3d(0, 100px, 0);
	transform: translate3d(0, 100px, 0);
	opacity: 0;

	position: relative;
	z-index: 2;
}

.MobilePortfolio_viewBox .MobilePortfolio_viewBox_inner > div{
	display: inline-block;
}

.MobilePortfolio_viewBox .MobilePortfolio_viewBox_inner .MobilePortfolio_viewBox_btn_close{
	width: .72rem;
	height: .72rem;
	background-color: rgba(0,0,0,.5);
	background-image: url(../resource/images/btn_close@2x.png);
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 0px 0px 0px .24rem;
	opacity: 0;

	-webkit-transition: opacity 360ms ease 660ms;
	transition: opacity 360ms ease 660ms;

	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 3;
}

.MobilePortfolio_viewBox .MobilePortfolio_viewBox_inner .MobilePortfolio_viewBox_btn_close:hover{
	background-color: rgba(0,0,0,.8);
}

.MobilePortfolio_viewBox .MobilePortfolio_viewBox_inner .ModelWindowBox{
	width: 100%;
	height: 100%;
	background-color: #fff;
	border-radius: .12rem .12rem 0px 0px;
	overflow-x: hidden;
	overflow-y: scroll;
	position: relative;
	z-index: 1;
}

.MobilePortfolio_viewBox .MobilePortfolio_viewBox_inner .MobileModelinfo{
	width: calc(100% - .6rem);
	height: auto;
	padding: 0px .3rem;
	background-color: #fff;

	-webkit-transform: translate3d(-180px, 0, 0);
	transform: translate3d(-180px, 0, 0);
}

.MobilePortfolio_viewBox .MobilePortfolio_viewBox_inner .MobileModelinfo .MobilePortfolioinfo_window{
	padding: .84rem 0px .48rem 0px;
	opacity: 0;
}

.MobilePortfolio_viewBox_hide{
	opacity: 0;
}

.MobilePortfolio_viewBox_show{
	opacity: 1;
	z-index: 100;
}

.MobilePortfolio_viewBox_show .MobilePortfolio_viewBox_inner{
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	opacity: 1;	
}

.MobilePortfolio_viewBox_show .MobilePortfolio_viewBox_inner .MobilePortfolio_viewBox_btn_close{
	opacity: 1;
}

.MobilePortfolio_viewBox_show .MobilePortfolio_viewBox_inner .MobileModelinfo{
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	/*opacity: 1;		*/
}

.MobilePortfolio_viewBox_show .MobilePortfolio_viewBox_inner .MobileModelinfo .MobilePortfolioinfo_window{
	opacity: 1;
}
/*MobilePortfolio_viewBox_end*/
