.Laboratory_window{
	width: 100%;
	height: auto;
}

.Laboratory_window .pic_default{
	width: 360px;
	height: 180px;
	margin: 0 auto;
	margin-top: 96px;
	background-image: url(../resource/images/default_close@2x.png);
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
}





