.MobileLaboratory_window{
	width: 100%;
	height: auto;
}

.MobileLaboratory_window .pic_default{
	width: 6rem;
	height: 3rem;
	margin: 0 auto;
	margin-top: 3.6rem;
	background-image: url(../resource/images/default_close@2x.png);
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
}





