@keyframes loading
{
	0% {opacity: 1;}
	50% {opacity: .3;}
	100% {opacity: 1;}
}
 
@-webkit-keyframes loading
{
	0% {opacity: 1;}
	50% {opacity: .3;}
	100% {opacity: 1;}
}



.Main_window{
	width: 100%;
	height: 100%;
	background-color: #101610;

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;

	overflow: hidden;
}

/*Main_loadingScreen*/
.Main_loadingScreen{
	width: 100%;
	height: 100%;
	background-color: #000f06;
	opacity: 1;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 9999;
}

.Main_loadingScreen .Main_loadingScreen_pic{
	width: 180px;
	height: 180px;
	margin: 0 auto;
	padding-top: 180px;
}

.Main_loadingScreen .loading_text{
	width: 100%;
	height: auto;
	font-size: 16px;
	color: #FFE18A;
	text-align: center;
	line-height: 1;
	font-weight: 400;
	text-shadow: 0px 0px 4px rgba(255, 255, 138, .6);
	margin-top: 12px;
	opacity: 1;
}

.Main_loadingScreen .loading_text i{
	font-style: normal;
	animation: loading 2s linear 0s infinite normal;
	-webkit-animation: loading 2s linear 0s infinite normal;
}

.Main_loadingScreen .loading_done{
	width: 186px;
	height: 20px;
	margin: 0 auto;
	background-image: url(../resource/images/logo_text@2x.png);
	background-size: 100% auto;
	background-position: bottom center;
	background-repeat: no-repeat;
	opacity: 0;
}

.Main_loadingDone{
	opacity: 0;
}

.Main_loadingDone .loading_text{
	height: 0px;
	opacity: 0;
}

.Main_loadingDone .loading_done{
	opacity: 1;
}

.Main_loadingNone{
	width: 0px;
	height: 0px;
	overflow: hidden;
	display: none !important;
	z-index: 1 !important;
}

/*Main_box*/
.Main_box{
	width: 100%;
	height: 100%;
	margin: 0 auto;

	position: relative;
	overflow: hidden; /*--->会导致高度超出，等待后续优化*/
	overflow-y: scroll;
	scrollbar-width: none;	/*兼容隐藏 Firefox 滚动条*/
	-moz-appearance: none;	/*兼容隐藏 Firefox 滚动条*/
}

.Main_box_bg{
	width: 100%;
	height: 100%;
	background-image: url(../resource/images/BG_normal.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	-webkit-filter:blur(30px);/*Chrome,Opera*/
	-moz-filter:blur(30px);
	-ms-filter:blur(30px);
	filter:blur(30px);
	filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius=30,MakeShadow=false);/*IE6~IE9*/

	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 1;
}

/*Main_headNav*/
.Main_headNav{
	width: 100%;
	height: auto;
	background-color: rgba(0, 0, 0, 0);

	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);

	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 3;
}

.Main_headNav ul{
	width: 100%;
	height: auto;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
}

.Main_headNav ul li{
	width: 136px;
	height: auto;
	padding: 18px 0px;
	position: relative;
}

.Main_headNav ul li:after{
	content: '';
	width: 0px;
	height: 2px;
	background-color: #FFE18A;
	border-radius: 100px;
	box-shadow: 0px -2px 6px rgba(255, 255, 138, .5);

	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 2;

	-webkit-transition: all 360ms ease;
	transition: all 360ms ease;

	-webkit-transform: translate3d(0, 6px, 0);
	transform: translate3d(0, 6px, 0);

	opacity: 0;
}

.Main_headNav ul li p{
	width: auto;
	height: auto;
	padding-left: 24px;
	font-size: 20px;
	text-align: left;
	line-height: 1;
	color: #FFE18A;
	font-weight: 500;
	text-shadow: 0px 2px 4px rgba(255, 255, 138, 0);

	position: relative;
	z-index: 3;

	opacity: .6;
}

.Main_headNav ul li em{
	width: 100%;
	height: auto;
	font-size: 16px;
	text-align: left;
	line-height: 1;
	color: #ffe18a;
	opacity: .1;
	font-weight: 400;

	position: absolute;
	bottom: 6px;
	left: 40px;
	z-index: 1;
}

.Main_headNav ul li:hover p{
	text-shadow: 0px 2px 4px rgba(255, 255, 138, 1);
	opacity: 1;
}

.Main_headNav ul li:hover em{
	-webkit-transform: translate3d(8px, 0, 0);
	transform: translate3d(8px, 0, 0);
	opacity: .3;
}

.Main_headNav ul li.nav_cur:after{
	width: 106px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	opacity: 1;
}

.Main_headNav ul li.nav_cur p{
	opacity: 1;
}

.Main_headNav ul li.nav_cur em{
	opacity: .3;
}

/*Main_logo*/
.Main_logo{
	width: 100%;
	height: auto;
/*	margin-top: 72px;*/
	padding: 18px 0px;

/*	position: relative;*/
	z-index: 2;

	position: fixed;
	top: 72px;
}

.Main_logo .logo_pic{
	width: 42px;
	height: 42px;
	margin: 0 auto;
	background-image: url(../resource/images/logo_main@2x.png);
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0px 2px 6px rgba(255, 236, 163, .6);
}

.Main_logo .logo_text{
	width: 186px;
	height: 20px;
	margin: 0 auto;
	background-image: url(../resource/images/logo_text@2x.png);
	background-size: 100% auto;
	background-position: bottom center;
	background-repeat: no-repeat;
	margin-top: 12px;
}

/*Scroll_fixed*/
	/*page_Main*/
.scrollMain .Main_headNav{
	z-index: 99;
	background-color: rgba(0, 0, 0, .7);	
}

.scrollMain .Main_logo{
	opacity: 0;
	z-index: 1;
}

.scrollMain .Portfolio_normalNav{
	display: none !important;
	z-index: 1 !important;
}

	/*page_Portfolio*/

.scrollPortfolio .Main_headNav{
	top: -60px;
	opacity: 0;
	z-index: 1 !important;
}

.scrollPortfolio .Main_logo{
	opacity: 0;
	z-index: 1 !important;
}

.scrollPortfolio .Portfolio_normalNav{
	opacity: 0;
	z-index: 1 !important;
}

.scrollPortfolio .Portfolio_fixedNav{
	top: 0px;
	opacity: 1;
	z-index: 99;
	background-color: rgba(0, 0, 0, .7);
	padding: 12px 0px 0px 0px;
}

	/*page_Laboratory*/
.scrollLaboratory .Main_headNav{
	z-index: 99;
	background-color: rgba(0, 0, 0, .7);	
}

.scrollLaboratory .Main_logo{
	opacity: 0;
	z-index: 1;
}

.scrollLaboratory .Portfolio_headNav{
	display: none !important;
	z-index: 1 !important;
}



.Window_PageList{
	width: 100%;
	height: 100%;

	position: relative;
	z-index: 2;

	margin-top: 182px;
}



/*Main_item*/
.Main_item{

}

.Main_item ul{
	width: 1200px;
	height: 100%;
	display: block;
	margin: 0 auto;
}

.Main_item li{
	width: 200px;
	height: 300px;
	border-radius: 4px;
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;

	pointer-events: auto; /*解决pointer-events:none,阻止anchor元素的默认行为 （涉及模块Parallax.js）*/

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;

	background-color: #eee;
}

.Main_item li p{
	width: 100%;
	height: auto;
	font-size: 24px;
	text-align: left;
	color: #ffe18a;
	line-height: 1;
	font-weight: 500;

	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	position: absolute;
	bottom: 24px;
	left: 36px;
	z-index: 3;
}

.Main_item li .icon_enter{
	width: 10px;
	height: 8px;
	background-image: url(../resource/images/icon_enter@2x.png);
	background-size: 70px 8px;
	background-position: top right;
	background-repeat: no-repeat;

	opacity: 0;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	position: absolute;
	bottom: 70px;
	left: 32px;
	z-index: 3;
}

.Main_item li em{
	width: 100%;
	height: auto;
	font-size: 60px;
	text-align: right;
	color: #ffe18a;
	line-height: 1;
	font-weight: 500;
	opacity: .1;

	position: absolute;
	bottom: 24px;
	left: 12px;
	z-index: 1;	
}

.Main_item li .mask{
	width: 100%;
	height: 100px;
	background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.8));

	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 2;
}

.Main_item .animate_hover:hover p{
	-webkit-transform: translate3d(0, -56px, 0);
	transform: translate3d(0, -56px, 0);
}

.Main_item .animate_hover:hover .icon_enter{
	width: 70px;
	opacity: 1;
	-webkit-transform: translate3d(6px, 0, 0);
	transform: translate3d(6px, 0, 0);
}

.Main_item .animate_hover:hover em{
	opacity: .4;
}

.Main_item .animate_hover:hover .mask{
	height: 320px;
}

/*page_Portfolio*/
.Main_item .page_Portfolio{
	box-shadow: 2px 4px 12px rgba(58, 68, 28, 1);
	border: 1px solid #46541E;
}

.Main_item .page_Portfolio:hover{
	box-shadow: 2px 4px 12px rgba(255, 255, 138, .5);
	border: 1px solid #FFE18A;
}

/*page_Laboratory*/
.Main_item .page_Laboratory{
	box-shadow: 2px 4px 12px rgba(26, 88, 26, .5);
	border: 1px solid #1D3616;
}

.Main_item .page_Laboratory:hover{
	box-shadow: 2px 4px 12px rgba(167, 236, 102, .5);
	border: 1px solid #2A5C1C;
}

/*switch pages*/
.Window_PageItem{
	width: 100%;
	height: 100%;

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}

.Switch_page_fadeIn{
	opacity: 1;
	z-index: 99 !important;
}

.Switch_page_fadeOut{
	opacity: 0;
/*	transform: scale3d(2, 2, 2);*/
	/*-webkit-transform: scale3d(2, 2, 2);*/ /* Safari 与 Chrome */

	z-index: 1 !important;
}

.Switch_page_fadeOut li{
	pointer-events: none;
}





/*Portfolio_item*/
.Portfolio_item{

}






/*Laboratory_item*/
.Laboratory_item{

}





