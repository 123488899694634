.Portfolio_window{
	width: 100%;
	height: auto;
}

/*Portfolio_headNav*/
.Portfolio_window .Portfolio_normalNav{
	width: 100%;
	height: auto;
	opacity: 1;

	position: relative;
	z-index: 2;
}

.Portfolio_window .Portfolio_headNav{
	width: 100%;
	height: auto;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
}

.Portfolio_headNav li{
	width: 90px;
	height: auto;
	margin: 0px 6px;
}

.Portfolio_headNav li:after{
	content: '';
	width: 0px;
	height: 2px;
	background-color: #FFE18A;
	border-radius: 100px;
	box-shadow: 0px -2px 6px rgba(255, 255, 138, .5);

	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 2;

	-webkit-transition: all 360ms ease;
	transition: all 360ms ease;

	-webkit-transform: translate3d(0, 6px, 0);
	transform: translate3d(0, 6px, 0);

	opacity: 0;
}

.Portfolio_headNav li p{
	width: auto;
	height: auto;
	padding: 10px 0px;
	font-size: 16px;
	text-align: center;
	line-height: 1;
	color: #FFE18A;
	text-shadow: 0px 2px 4px rgba(255, 255, 138, 0);

	position: relative;
	z-index: 3;

	opacity: .6;
}

.Portfolio_headNav li:hover p{
	text-shadow: 0px 2px 4px rgba(255, 255, 138, 1);
	opacity: 1;
}

.Portfolio_headNav li.nav_cur:after{
	width: 100%;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	opacity: 1;
}

.Portfolio_headNav li.nav_cur p{
	opacity: 1;
}

.Portfolio_headNav li.nav_cur em{
	opacity: .3;
}

.Portfolio_fixedNav{
	width: 100%;
	height: auto;
	background-color: rgba(0, 0, 0, 0);

	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);

	position: fixed;
	top: -36px;
	left: 0px;
	z-index: -1;
}

/*Portfolio_list*/
.Portfolio_WaterfallFlow{
	width: 1088px;
	height: auto;
	padding: 60px 30px;
	margin: 0 auto;
/*	overflow-x: hidden;*/
/*	overflow-y: scroll;*/
	position: relative;
	z-index: 2;
}

.Portfolio_list{
	width: 100%;
	height: auto;
	padding-bottom: 60px;
	/*padding: 60px 0px;*/
	/*margin: 0 auto;*/
	flex-wrap: wrap;
	position: relative;
	z-index: 2;
}

.Portfolio_list li{
	width: 260px;
	height: auto;
	padding: 0px;
	/*margin: 0px 15px 30px 15px;*/
	list-style: none;
	background-color: #40574C;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 4px;
	border: 1px solid #1D3616;
	box-shadow: 2px 4px 12px rgba(26, 88, 26, .5);
	overflow: hidden;
	opacity: 0;

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 2;
}

.Portfolio_list li p{
	width: calc(100% - 36px);
	height: auto;
	padding: 12px 12px;
	margin: 0px;
	font-size: 14px;
	font-weight: bold;
	color: #ffffff;
	line-height: 1.2;
	-webkit-transform: translate3d(-6px, 0, 0);
	transform: translate3d(-6px, 0, 0);

	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 3;

	opacity: 0;
}

.Portfolio_list li:after{
	content: '';
	width: 100%;
	height: 100%;
	background-image: linear-gradient(180deg, transparent 62%, rgba(0,0,0,0.00345888) 63.94%, rgba(0,0,0,0.014204) 65.89%, rgba(0,0,0,0.0326639) 67.83%, rgba(0,0,0,0.0589645) 69.78%, rgba(0,0,0,0.0927099) 71.72%, rgba(0,0,0,0.132754) 73.67%, rgba(0,0,0,0.177076) 75.61%, rgba(0,0,0,0.222924) 77.56%, rgba(0,0,0,0.267246) 79.5%, rgba(0,0,0,0.30729) 81.44%, rgba(0,0,0,0.341035) 83.39%, rgba(0,0,0,0.367336) 85.33%, rgba(0,0,0,0.385796) 87.28%, rgba(0,0,0,0.396541) 89.22%, rgba(0,0,0,0.4) 91.17%);

	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 2;

	-webkit-transition: all 360ms ease;
	transition: all 360ms ease;
	opacity: 0;
}

.Portfolio_list li:hover{
	/*-webkit-transform: translate3d(0, -6px, 0);*/
	/*transform: translate3d(0, -6px, 0);*/
	border: 1px solid #FFE18A;
	box-shadow: 0px 12px 36px rgba(255, 255, 138, .5);

	top: -6px;
}

.Portfolio_list li:hover p{
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

.Portfolio_list li:hover:after{
	opacity: 1;
}

/*loading*/
.Portfolio_loadingScreen{
/*	width: 100%;*/
/*	height: 100%;*/
/*	margin-top: 180px;*/
/*	position: absolute;*/
/*	top: 0px;*/
/*	left: 0px;*/
/*	z-index: 1;*/

	width: 1088px;
	height: auto;
	margin-top: 120px;
	position: fixed;
	z-index: 1;

	opacity: 0;

/*	opacity: 1 !important;*/
/*	z-index: 199 !important;*/
}

.Portfolio_loadingScreen_inner{
	width: 160px;
	height: auto;
	margin: 0 auto;
	padding: 12px 24px 18px 24px;
	background-color: rgba(0,0,0,.7);

	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);

	border-radius: 12px;
}

.Portfolio_loadingScreen .Portfolio_loadingScreen_pic{
	width: 100%;
	height: auto;
	margin: 0 auto;
}

.Portfolio_loadingScreen .loading_text{
	width: 100%;
	height: auto;
	font-size: 16px;
	color: #FFE18A;
	text-align: center;
	line-height: 1;
	font-weight: 400;
	text-shadow: 0px 0px 4px rgba(255, 255, 138, .6);
	margin-top: 12px;
}

.Portfolio_loadingShow{
	opacity: 1;
	z-index: 3;
}

.Portfolio_loadingClose{
	opacity: 0;
	z-index: 1;
}

/*Portfolio_viewBox_start*/
.Portfolio_viewBox{
	width: 100%;
	height: 100%;
	overflow: hidden;

	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 1;
}

.Portfolio_viewBox .Portfolio_viewBox_Mask{
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, .36);

	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);

	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;	
}

.Portfolio_viewBox .Portfolio_viewBox_inner{
	width: 1020px; /*---备选*/
	width: 92%;
	height: 96%;
	max-height: 720px;
	margin: 0 auto;

	-webkit-transform: translate3d(0, 100px, 0);
	transform: translate3d(0, 100px, 0);
	opacity: 0;

	position: relative;
	z-index: 2;
}

.Portfolio_viewBox .Portfolio_viewBox_inner > div{
	display: inline-block;
}

.Portfolio_viewBox .Portfolio_viewBox_inner .Portfolio_viewBox_btn_close{
	width: 30px;
	height: 30px;
	background-color: rgba(0,0,0,.5);
	background-image: url(../resource/images/btn_close@2x.png);
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 0px 0px 0px 12px;
	opacity: 0;

	-webkit-transition: opacity 360ms ease 660ms;
	transition: opacity 360ms ease 660ms;

	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 3;
}

.Portfolio_viewBox .Portfolio_viewBox_inner .Portfolio_viewBox_btn_close:hover{
	background-color: rgba(0,0,0,.8);
}

.Portfolio_viewBox .Portfolio_viewBox_inner .ModelWindowBox{
	width: 720px; /*---备选*/
	width: calc(100% - 260px);
	height: 100%;
	background-color: #fff;
	border-radius: 12px 0px 0px 12px;
	overflow-x: hidden;
	overflow-y: scroll;
	scrollbar-width: none;	/*兼容隐藏 Firefox 滚动条*/
	-moz-appearance: none;	/*兼容隐藏 Firefox 滚动条*/
	position: relative;
	z-index: 1;
}

.Portfolio_viewBox .Portfolio_viewBox_inner .Modelinfo{
	width: 240px; /*---备选*/
	width: 200px;
	height: 100%;
	padding: 0px 30px;
	background-color: #fff;
	border-radius: 0px 12px 12px 0px;
	overflow-x: hidden;
	overflow-y: scroll;
	scrollbar-width: none;	/*兼容隐藏 Firefox 滚动条*/
	-moz-appearance: none;	/*兼容隐藏 Firefox 滚动条*/
	box-shadow: 0px 0px 6px rgba(0, 0, 0, .15);
	position: relative;
	z-index: 2;

	-webkit-transform: translate3d(-180px, 0, 0);
	transform: translate3d(-180px, 0, 0);
}

.Portfolio_viewBox .Portfolio_viewBox_inner .Modelinfo .Portfolioinfo_window{
	padding: 36px 0px 60px 0px;
	opacity: 0;
}

.Portfolio_viewBox_hide{
	opacity: 0;
}

.Portfolio_viewBox_show{
	opacity: 1;
	z-index: 100;
}

.Portfolio_viewBox_show .Portfolio_viewBox_inner{
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	opacity: 1;	
}

.Portfolio_viewBox_show .Portfolio_viewBox_inner .Portfolio_viewBox_btn_close{
	opacity: 1;
}

.Portfolio_viewBox_show .Portfolio_viewBox_inner .Modelinfo{
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	/*opacity: 1;		*/
}

.Portfolio_viewBox_show .Portfolio_viewBox_inner .Modelinfo .Portfolioinfo_window{
	opacity: 1;
}
/*Portfolio_viewBox_end*/



/*适配_start*/
@media not screen and (max-width: 1200px) {
	.Portfolio_viewBox .Portfolio_viewBox_inner{
		width: 96%;
		max-width: 1200px;
	}

	.Portfolio_viewBox .Portfolio_viewBox_inner .ModelWindowBox{
		width: calc(100% - 300px);
	}

	.Portfolio_viewBox .Portfolio_viewBox_inner .Modelinfo{
		width: 240px;
	}
}
/*适配_end*/
